/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Tab } from "react-bootstrap";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDispatch } from "react-redux";
import locationActions from "../redux/actions/locationAction";

const Banner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectLocation, setSelectLocation] = useState(null);

  const panTo = React.useCallback(
    ({
      lat,
      lng,
      city,
      state,
      country,
      unparsedAddress,
      zipCode,
      streetName,
      streetNumber,
    }) => {
      let query = "";
      if (unparsedAddress) {
        query += `address=` + unparsedAddress;
        dispatch(locationActions.setUnparsedAddress(unparsedAddress));
      }
      if (city) {
        query += `&city=` + city;
        dispatch(locationActions.setCity(city));
      }
      if (state) {
        query += `&state=` + state;
        dispatch(locationActions.setState(state));
      }
      if (country) {
        query += `&country=` + country;
        dispatch(locationActions.setCountry(country));
      }
      if (lat) {
        query += `&lat=` + lat;
        dispatch(locationActions.setLat(lat));
      }
      if (lng) {
        query += `&lng=` + lng;
        dispatch(locationActions.setLng(lng));
      }
      if (zipCode) {
        query += `&zipCode=` + zipCode;
        dispatch(locationActions.setZipCode(zipCode));
      }
      if (streetName) {
        query += `&streetName=` + streetName;
        dispatch(locationActions.setStreetName(streetName));
      }
      if (streetNumber) {
        query += `&streetNumber=` + streetNumber;
        dispatch(locationActions.setStreetNumber(streetNumber));
      }

      console.log(query);

      setSelectLocation(query);
      dispatch(locationActions.setDetails(query));
    },
    []
  );

  return (
    <>
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="banner"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-sm-12">
              <h1>
                UNCOVER YOUR COMMERCIAL AND RESIDENTIAL PROPERTIES IN SOCAL
              </h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10 col-sm-12">
            <div className="banner-image">
              <img
                src={require("../assets/homepage-banner.webp")}
                alt="DISCOVER YOUR DREAM HOME IN SOUTHERN CALIFORNIA"
              />
            </div>
          </div>
        </div>
      </motion.section>

      {/* LISTING TABS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="banner-tabs"
      >
        <div className="container">
          <Tab.Container id="listing-tabs" defaultActiveKey="All">
            <div className="row">
              <div className="col-xl-12 col-sm-12">
                <div className="bg-white">
                  <h5>Search our database of homes by address</h5>
                  <form className="listing-search">
                    <Search panTo={panTo} />
                    <Button
                      onClick={() => {
                        navigate("/find-a-home?" + selectLocation);
                      }}
                      className="search-button"
                    >
                      SEARCH LISTINGS
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Tab.Container>
        </div>
      </motion.div>
    </>
  );
};

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
      componentRestrictions: { country: "us" },
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });

      const { lat, lng } = await getLatLng(results[0]);

      const AddressComponent = results?.[0]?.address_components;

      let country = await AddressComponent?.find(
        (type) => type?.types?.[0] === "country"
      ).short_name;
      let state = null;
      let city = null;
      let unparsedAddress = null;
      let zipCode = null;
      let streetNumber = null;
      let streetName = null;
      console.log("ADDRESS", AddressComponent);

      if (
        AddressComponent?.find(
          (type) => type?.types?.[0] === "street_number"
        ) !== undefined &&
        AddressComponent?.find((type) => type?.types?.[0] === "route") !==
          undefined
      ) {
        unparsedAddress =
          AddressComponent?.find((type) => type?.types?.[0] === "street_number")
            .long_name +
          " " +
          AddressComponent?.find((type) => type?.types?.[0] === "route")
            .long_name;
      }
      if (
        AddressComponent?.find(
          (type) => type?.types?.[0] === "administrative_area_level_1"
        )
      ) {
        state = await AddressComponent?.find(
          (type) => type?.types?.[0] === "administrative_area_level_1"
        ).short_name;
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === "locality")) {
        city = await AddressComponent?.find(
          (type) => type?.types?.[0] === "locality"
        ).long_name;
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === "route")) {
        streetName = await AddressComponent?.find(
          (type) => type?.types?.[0] === "route"
        ).long_name;
      }
      if (
        AddressComponent?.find((type) => type?.types?.[0] === "street_number")
      ) {
        streetNumber = await AddressComponent?.find(
          (type) => type?.types?.[0] === "street_number"
        ).long_name;
      }
      if (
        AddressComponent?.find((type) => type?.types?.[0] === "postal_code")
      ) {
        zipCode = await AddressComponent?.find(
          (type) => type?.types?.[0] === "postal_code"
        ).long_name;
      }

      console.log(AddressComponent);
      panTo({
        lat,
        lng,
        city,
        state,
        country,
        unparsedAddress,
        zipCode,
        streetName,
        streetNumber,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="search p-relative w-100"
    >
      <div className="form-group p-relative mb-0 ">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            value={value}
            id="address-input"
            onChange={handleInput}
            disabled={!ready}
            className="form-control"
            placeholder="Search your city or neighborhood"
            onFocus={(e) => e.target.setAttribute("autoComplete", "off")}
            autoComplete={"off"}
          />
          <ComboboxPopover className="combobbox-popover-styles">
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    </motion.div>
  );
}

export default Banner;

import { motion } from "framer-motion";

const TestimonialBox = (props) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="testimonial-box"
    >
      <h5>{props.title}</h5>
      <p>{props.description}</p>
      <div className="testimonial-profile">
        <img src={props.userProfile} alt={props.name} />
        <div className="profile-details">
          <h5>{props.name}</h5>
          <p>{props.userRole}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Testimonials = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="testimonials"
    >
      <div className="container">
        <div className="heading">
          <h2>WHAT CLIENTS SAY ABOUT ME</h2>
          <h5>
            Read what our satisfied clients have to say about their experience
            working with me.
          </h5>
        </div>
        <div className="row mt-4">
          <div className="col-xl-4 col-sm-12">
            <TestimonialBox
              title="Best Selling Experience"
              description="I had the recent privilege of collaborating with Rene to sell my home, and I cannot express enough how highly I recommend them. The entire process, from beginning to end, was smooth and free of stress."
              userProfile={require("../assets/testimonial-01.jpg")}
              name="James Smith"
              userRole="Real Estate Seller"
            />
          </div>
          <div className="col-xl-4 col-sm-12">
            <TestimonialBox
              title="Great Experience"
              description="Due to Rene's diligent efforts and proficiency, I swiftly acquired my dream home at a cost fitting my budget. I highly recommend him to those seeking property transactions and wants to buy their dream house."
              userProfile={require("../assets/testimonial-02.jpg")}
              name="Carolina Williams"
              userRole="Real Estate Buyer"
            />
          </div>
          <div className="col-xl-4 col-sm-12">
            <TestimonialBox
              title="Easy Selling Experience"
              description="Rene's exceptional guidance and market insight led to a seamless home buying experience. His professionalism and commitment surpassed expectations. Highly recommend for top-notch real estate service."
              userProfile={require("../assets/testimonial-03.jpg")}
              name="Robert Miller"
              userRole="Real Estate Seller"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Testimonials;

import React from "react";
import Header from "./header";
import Footer from "./footer";

/* IMPORT react-toastify FOR TOAST NOTIFICATION  */
import { ToastContainer } from 'react-toastify'

/* IMPORT REACT-TOASTIFY CSS  */
import 'react-toastify/dist/ReactToastify.css'

const Layout = ({ children }) => {
  return (
    <main>
      <Header />
      <ToastContainer/>
      <div className="main">{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;

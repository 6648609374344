const SERVER_URL =
  "https://amberwoodre-backend-nodejs-cpmn6gngia-wl.a.run.app/";
// const SERVER_URL = "http://192.168.29.179:8080/";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw";
const INSTAGRAM_KEY =
  "IGQVJVZA0lwX2wxUVEtbXlQNGVXSnY1STVvbmNiaENYRXVkLVNYY3pSSVRUdFE0eU5WTkRXdFlzQWREMndDTDgtWjktb1B2V3dNbGFka1E1bEFJNnRhRDB6RXVpY3ZAaQUN6dXRmV21kMGNTRFRoYVNkVgZDZD";
const IMAGE_URL = "https://storage.googleapis.com/cdn-amberwoodre/";
const AGENT_ID = "64e4f302a5e317e75dd6f9f2";
module.exports = {
  SERVER_URL,
  YOUR_GOOGLE_MAPS_API_KEY,
  INSTAGRAM_KEY,
  IMAGE_URL,
  AGENT_ID,
};
